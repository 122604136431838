<template>
  <footer id="footer" class="footer">
    <div :class="{
      'comparison': $route.name.includes('comparison'),
      'search-page': $route.name.includes('search')
    }" class="footer_wrap container">
      <div class="lside">
        <Logo class="logo" />
        <div class="f gap8 mta mb16 hm">
          <a aria-label="VK page" class="messenger-link" href="https://vk.com/csmarketcap"
             target="_blank">
            <svg height="24" width="24">
              <use xlink:href="/icons/sprite.svg#i-vk"></use>
            </svg>
          </a>
        </div>
        <span class="copyright hm mt0">
          {{ `${new Date().getFullYear()} © CSMARKETCAP.COM` }}
        </span>
      </div>
      <div class="rside">
        <FooterComponentsMenu />
      </div>
      <div class="f gap8 mta ht hd">
        <a aria-label="VK page" class="messenger-link" href="https://vk.com/csmarketcap"
           target="_blank">
          <svg height="24" width="24">
            <use xlink:href="/icons/sprite.svg#i-vk"></use>
          </svg>
        </a>
      </div>
      <span class="copyright show-md-min">
          {{ `${new Date().getFullYear()} © CSMARKETCAP.COM` }}
      </span>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'
import {useResize} from '~/composables/resize'
import Logo from '~/components/Header/Components/Logo.vue'

const {t} = useI18n()
const currW = useResize()
</script>


<style lang="scss" scoped>
@use '/assets/scss/mixins.scss' as *;

.footer {
  width: 100%;
  border-top: 1px solid $darkLight;
  margin-top: auto;
  background: $black;

  :deep(.logo) {
    margin-right: 0;
  }

  .messenger-link {
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  &_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 3.4rem auto;

    @media screen and (max-width: 2000px) and (min-width: 1500px) {
      &.comparison {
        margin-left: 370px;
        width: calc(100% - 400px);
      }
    }

    &.search-page {
      @media screen and (max-width: 2000px) and (min-width: 1115px) {
        margin-left: 37rem;
        width: unset;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    .copyright {
      margin-top: auto;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: rgba(168, 182, 210, 0.8);

      &.show-md-min {
        display: none;
        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      @media screen and (max-width: 768px) {
        margin-top: 3.2rem;
      }
    }

    .lside {
      display: flex;
      flex-direction: column;

    }

    .rside {
      text-align: right;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
  }
}
</style>

