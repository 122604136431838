<template>
  <ul class="footer-menu">
    <li class="menu-item">
      <!--      <span class="item-title">CS:GO {{ t('markets') }}</span>-->
      <ul class="item-links">
        <li>
          <NuxtLink :to="localePath('/')">
            <span class="link-title">{{ t('main') }}</span>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
              :to="localePath('/comparison')"
          >
            <span class="link-title">{{ t('price_comparison') }}</span>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="localePath('/discord')">
            <span class="link-title"> {{ t('discord_bot') }}</span>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="localePath('/blog')">
            <span class="link-title"> {{ t('blog') }}</span>
          </NuxtLink>
        </li>
      </ul>
    </li>
    <!-- <li class="menu-item">
      <span class="item-title">{{ t("Company") }}</span>
      <ul class="item-links">
        <li>
          <NuxtLink :to="#"
            ><span class="link-title">{{ t("API") }}</span>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="#">
            <span class="link-title">{{ t("Terms of Service") }}</span>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="#">
            <span class="link-title">{{ t("Privacy Policy") }}</span>
          </NuxtLink>
        </li>
      </ul>
    </li> -->
  </ul>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'
import {useLocalePath} from "#i18n";

const localePath = useLocalePath()

const {t, locale} = useI18n()
</script>

<style lang="scss" scoped>
@use '/assets/scss/mixins.scss' as *;

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.footer-menu {
  .menu-item {
    min-height: 140px;

    .item-title {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $lighten;
    }

    .item-links {
      margin-top: 2.05rem;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
    }

    .link-title {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $light;

      &:hover {
        color: $lighten;
      }
    }
  }
}
</style>
