<template>
  <header id="header" class="header">
    <div class="header_wrap">
      <div class="container f">
        <div class="lside f fg">
          <Logo />
          <Nav />
        </div>
        <div class="rside f gap8">
          <!--          <client-only>-->
          <FilterTopComponentsSearch class="hm" header />
          <Currency />
          <LanguageDD />
          <HeaderComponentsLogin class="hm" />
          <!--          </client-only>-->
        </div>
      </div>
    </div>
    <!--    <client-only>-->
    <LazyHeaderComponentsNavMobile class="hlgt" />
    <!--    </client-only>-->
  </header>
</template>

<script lang="ts" setup>
import Logo from './Components/Logo.vue'
import Nav from './Components/Nav.vue'
import Currency from './Components/CurrencyDD.vue'

import {useResize} from '~/composables/resize.js'
import LanguageDD from "~/components/Header/Components/LanguageDD.vue";

const currW = useResize()
</script>

<style lang="scss" scoped>
@use '/assets/scss/mixins.scss' as *;

.header {
  background: $black;
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;

  &_wrap {
    padding: 1.5rem 0;
    background: $black;
    position: relative;
    z-index: 15;
  }
}

@media screen and (max-width: 1250px) {
  .header {
    &_top {
      display: none;
    }

    &_wrap {
      // border-top: none;
      // padding: 0;
      // // height: 5.3rem;
      // height: 6rem;
      // display: flex;
      // align-items: center;
      .nav {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 979px) {
  .header {
    padding-right: 5rem;

    &_top {
      display: none;
    }

    &_wrap {
      border-top: none;
      padding: 0;
      height: 5.3rem;
      display: flex;
      align-items: center;

      .nav {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
.logo {
  img {
    height: 3.2rem;
  }
}
</style>
